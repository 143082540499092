<template>
  <div>
    <v-row align="center" justify="space-between">
      <v-col cols="12" md="10">
        <atoms-text-field v-model="searchSalaryWeeks" label="Ketikkan nama data.." :noLabel="true" />
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-space-between">
        <atoms-button
          class="primary lighten-5 primary--text"
          style="width: 100%"
          @click="
            () => {
              showDialogCreateSalaryWeek = true
            }
          "
        >
          <v-icon left>mdi-plus</v-icon>
          Buat Data Upah Mingguan
        </atoms-button>
      </v-col>

      <v-col cols="12">
        <div v-if="!detailSalaryWeek">
          <div v-if="mainLoading" class="mt-4">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="list-item-avatar-three-line"
              class="accent rounded-lg mb-2"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </div>
          <!-- Data table salary weeks -->
          <v-data-table
            v-else
            :headers="headersDataTableSalaryWeeks"
            :items="dataSalaryWeeks"
            :search="searchSalaryWeeks"
          >
            <template v-slot:item.startDate="{ item }">
              {{
                new Date(item.startDate).toLocaleDateString('id-ID', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }}
            </template>

            <template v-slot:item.endDate="{ item }">
              {{
                new Date(item.endDate).toLocaleDateString('id-ID', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }}
            </template>

            <template v-slot:item.remark_1="{ item }">
              <v-chip v-if="item.remark_1" color="green" small>
                {{ item.remark_1 }}
              </v-chip>
              <v-chip v-else color="amber" small> Tidak diset </v-chip>
            </template>

            <template v-slot:item.isCompleted="{ item }">
              {{ item.isCompleted ? 'Selesai' : 'Belum Selesai' }}
            </template>

            <template v-slot:item._id="{ item }">
              <v-btn color="teal" small text icon @click="showDetailSalaryWeek(item)">
                <v-icon small> mdi-eye </v-icon>
              </v-btn>
              <v-btn color="red" small text icon @click="deleteSalaryWeek(item)">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <!-- Detail salary weeks -->
        <div v-else>
          <v-btn text color="green" @click="generateXlsx">
            <v-icon> mdi-file-export-outline </v-icon>
            Export
          </v-btn>
          <v-btn text color="red" @click="refreshSalaryWeek">
            <v-icon> mdi-refresh </v-icon>
            Refresh Data
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="
              () => {
                detailSalaryWeek = false
              }
            "
          >
            Kembali
          </v-btn>
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab v-for="itemTab in itemTabs" :key="itemTab">{{ itemTab }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="itemTab in itemTabs" :key="itemTab">
              <v-card v-if="itemTab === 'Edit Salary Week'">
                <v-divider />
                <v-card-text class="mt-4">
                  <v-text-field v-model="titleUpdate" label="Judul" outlined dense />

                  <v-text-field
                    v-model="searchClientUpdate"
                    label="Pilih Klien"
                    :disabled="disabledSearchClientUpdate"
                    outlined
                    dense
                    ref="searchClientFieldUpdate"
                  />

                  <div v-if="dataClients.length > 0" class="mb-10">
                    <div v-for="(client, key) in dataClients" :key="key">
                      <v-card flat>
                        <v-card-text class="py-0">
                          <v-checkbox
                            v-model="clientIdUpdate"
                            :label="client.name"
                            :value="client._id"
                            hide-details
                            dense
                          />
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>

                  <v-menu
                    v-model="menuDatePickerStartDateUpdate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDateUpdate"
                        label="Tanggal Mulai"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDateUpdate"
                      @input="menuDatePickerStartDateUpdate = false"
                      color="primary"
                    />
                  </v-menu>

                  <v-select
                    v-model="labelRemarkSelectedUpdate"
                    :items="listLabelRemark"
                    label="Pilih label remark"
                    outlined
                    dense
                  />

                  <v-text-field
                    v-if="labelRemarkSelectedUpdate === 'ManualEntry'"
                    v-model="remark1Update"
                    label="Remark"
                    outlined
                    dense
                  />

                  <v-switch v-model="isCompletedUpdate" label="Selesai" dense hide-details />
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-end">
                  <v-btn color="primary" @click="updateSalaryWeek"> Perbaharui data </v-btn>
                </v-card-actions>
              </v-card>

              <v-card v-else-if="itemTab === 'Workers'">
                <atoms-text-field v-model="searchWorker" label="Ketikkan nama worker.." :noLabel="true" />
                <!--                <v-text-field v-model="searchWorker" label="Ketikkan nama worker.." />-->
                <v-card-text class="py-10">
                  <v-data-table
                    :headers="headersTableWorkers"
                    :items="workersDetail"
                    :single-expand="singleExpandTableWorkers"
                    :expanded.sync="expandedTableWorkers"
                    :search="searchWorker"
                    item-key="workerId"
                    show-expand
                  >
                    <template v-slot:item.totalSalary="{ item }">
                      {{ convertToRupiah(item.totalSalary) }}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" class="grey lighten-4 py-5">
                        <v-row>
                          <v-col cols="12">
                            <v-toolbar dense flat>
                              <v-toolbar-title> Data Absensi </v-toolbar-title>
                            </v-toolbar>
                            <v-data-table
                              dense
                              disable-filtering
                              :headers="headersDataTableAttendancesDetailWorker"
                              :items="
                                attendancesDetail.filter((attendance) => {
                                  return attendance.workerId !== undefined && attendance.workerId === item.workerId
                                })
                              "
                            >
                              <template v-slot:item.checkIn="{ item }">
                                <span class="primary--text">{{
                                  item.checkIn ? new Date(item.checkIn).toLocaleString('id-ID') : 'Belum Mulai'
                                }}</span>
                              </template>
                              <template v-slot:item.checkOut="{ item }">
                                <span class="error--text">{{
                                  item.checkOut ? new Date(item.checkOut).toLocaleString('id-ID') : 'Belum Selesai'
                                }}</span>
                              </template>
                              <template v-slot:item.salaryForThisShift="{ item }">
                                {{ convertToRupiah(item.salaryForThisShift) }}
                              </template>
                              <template v-slot:item.isPaid="{ item }">
                                <v-chip :color="item.isPaid ? 'success' : 'error'" text-color="white" small>
                                  {{ item.isPaid ? 'Sudah Dibayar' : 'Belum Dibayar' }}
                                </v-chip>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                        <v-row class="mt-3">
                          <v-col cols="12">
                            <v-toolbar dense flat>
                              <v-toolbar-title> Data Shifts </v-toolbar-title>
                            </v-toolbar>
                            <v-data-table
                              dense
                              :headers="headersDataTableShiftsDetailWorker"
                              :items="
                                shiftsDetail.filter((shift) => {
                                  // shift.workerIds.includes(item.workerId)
                                  return shift.workerId !== undefined && shift.workerId === item.workerId
                                })
                              "
                            >
                              <template v-slot:item.startDate="{ item }">
                                {{ item.startDate.split('T')[0] }}
                              </template>
                              <template v-slot:item.endDate="{ item }">
                                {{ item.endDate.split('T')[0] }}
                              </template>
                              <template v-slot:item.fee="{ item }">
                                {{ convertToRupiah(item.fee) }}
                              </template>
                              <template v-slot:item.totalFee="{ item }">
                                {{ convertToRupiah(item.totalFee) }}
                              </template>
                              <template v-slot:item.priceBase="{ item }">
                                {{ convertToRupiah(item.priceBase) }}
                              </template>
                              <template v-slot:item.priceProposed="{ item }">
                                {{ convertToRupiah(item.priceProposed) }}
                              </template>
                              <template v-slot:item.purePriceProposed="{ item }">
                                {{ convertToRupiah(item.purePriceProposed) }}
                              </template>
                              <template v-slot:item.totalBase="{ item }">
                                {{ convertToRupiah(item.totalBase) }}
                              </template>
                              <template v-slot:item.totalProposed="{ item }">
                                {{ convertToRupiah(item.totalProposed) }}
                              </template>
                              <template v-slot:item.pureTotalProposed="{ item }">
                                {{ convertToRupiah(item.pureTotalProposed) }}
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>

              <v-card v-else>
                <v-card-text>
                  <!-- {{ summary }} -->
                  <h3>Total Workers: {{ summary?.totalWorkers }}</h3>
                  <h3>Total Attendances: {{ summary?.totalAttendances }}</h3>
                  <h3>Total Shifts: {{ summary?.totalShifts }}</h3>
                  <h3>Total Salary: {{ convertToRupiah(summary?.totalSalary) }}</h3>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>

    <!--Dialog create salary week-->
    <v-dialog v-model="showDialogCreateSalaryWeek" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Buat Data Upah Mingguan
          <v-spacer />
          <v-btn
            icon
            @click="
              () => {
                showDialogCreateSalaryWeek = false
              }
            "
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <v-text-field v-model="title" label="Judul" outlined dense />

          <v-text-field
            v-model="searchClient"
            label="Pilih Klien"
            :disabled="disabledSearchClient"
            outlined
            dense
            ref="searchClientField"
          />

          <div v-if="dataClients.length > 0" class="mb-10">
            <div v-for="(client, key) in dataClients" :key="key">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-checkbox v-model="clientId" :label="client.name" :value="client._id" hide-details dense />
                </v-card-text>
              </v-card>
            </div>
          </div>

          <v-menu
            v-model="menuDatePickerStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Tanggal Mulai"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="startDate" @input="menuDatePickerStartDate = false" />
          </v-menu>

          <v-select v-model="labelRemarkSelected" :items="listLabelRemark" label="Pilih label remark" outlined dense />

          <v-text-field v-if="labelRemarkSelected === 'ManualEntry'" v-model="remark1" label="Remark" outlined dense />

          <v-switch v-model="isCompleted" label="Selesai" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="createSalaryWeek"> Buat data </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'salary_week_review',

  data: () => ({
    mainLoading: true,
    searchClient: null,
    disabledSearchClient: false,
    dataClients: [],
    showDialogCreateSalaryWeek: false,
    title: null,
    clientId: null,
    menuDatePickerStartDate: false,
    startDate: null,
    menuDatePickerEndDate: false,
    endDate: null,
    listLabelRemark: ['Clients', 'Areas', 'Locations', 'Shifts', 'ManualEntry'],
    labelRemarkSelected: null,
    remark1: null,
    isCompleted: false,
    headersDataTableSalaryWeeks: [
      {
        text: 'Judul',
        align: 'start',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Nama Klien',
        align: 'start',
        sortable: false,
        value: 'clientName',
      },
      {
        text: 'Tanggal Mulai',
        align: 'start',
        sortable: false,
        value: 'startDate',
      },
      {
        text: 'Tanggal Selesai',
        align: 'start',
        sortable: false,
        value: 'endDate',
      },
      {
        text: 'Label remark',
        align: 'start',
        sortable: false,
        value: 'label_remark_1',
      },
      {
        text: 'Remark',
        align: 'start',
        sortable: false,
        value: 'remark_1',
      },
      {
        text: 'Selesai',
        align: 'start',
        sortable: false,
        value: 'isCompleted',
      },
      {
        text: 'Aksi',
        align: 'center',
        sortable: false,
        value: '_id',
      },
    ],
    dataSalaryWeeks: [],
    searchSalaryWeeks: null,

    detailSalaryWeek: false,
    titleUpdate: null,
    clientIdUpdate: null,
    clientNameUpdate: null,
    startDateUpdate: null,
    endDateUpdate: null,
    labelRemarkSelectedUpdate: null,
    remark1Update: null,
    isCompletedUpdate: false,
    searchClientUpdate: null,
    disabledSearchClientUpdate: false,
    _idUpdate: null,

    menuDatePickerStartDateUpdate: false,
    shiftsDetail: [],
    attendancesDetail: [],
    workersDetail: [],

    tab: null,
    itemTabs: ['Edit Salary Week', 'Workers', 'Summary'],

    headersTableWorkers: [
      {
        text: 'Nama Pekerja',
        align: 'start',
        sortable: false,
        value: 'workerName',
      },
      {
        text: 'Nama Akun',
        align: 'start',
        sortable: false,
        value: 'workerAccountName',
      },
      {
        text: 'Nomor Pekerja',
        align: 'start',
        sortable: false,
        value: 'workerAccountNumber',
      },
      {
        text: 'Nama Bank Pekerja',
        align: 'start',
        sortable: false,
        value: 'workerBankName',
      },
      {
        text: 'Remark 1',
        align: 'start',
        sortable: false,
        value: 'remark_1',
      },
      {
        text: 'Total Salary',
        align: 'start',
        sortable: false,
        value: 'totalSalary',
      },
      {
        text: 'Total Attendance',
        align: 'center',
        sortable: false,
        value: 'totalAttendances',
      },
      {
        text: 'Paid Attendance',
        align: 'center',
        sortable: false,
        value: 'paidAttendances',
      },
    ],
    expandedTableWorkers: [],
    singleExpandTableWorkers: true,

    headersDataTableAttendancesDetailWorker: [
      {
        text: 'Tanggal & Waktu Check In',
        align: 'start',
        sortable: false,
        value: 'checkIn',
      },
      {
        text: 'Tanggal & Waktu Check Out',
        align: 'center',
        sortable: false,
        value: 'checkOut',
      },
      {
        text: 'Nama Area',
        align: 'center',
        sortable: false,
        value: 'areaName',
      },
      {
        text: 'Nama Lokasi',
        align: 'center',
        sortable: false,
        value: 'locationName',
      },
      {
        text: 'Nama Shift',
        align: 'center',
        sortable: false,
        value: 'shiftName',
      },
      {
        text: 'Pembayaran untuk shift ini',
        align: 'center',
        sortable: false,
        value: 'salaryForThisShift',
      },
      {
        text: 'Status Pembayaran',
        align: 'center',
        sortable: false,
        value: 'isPaid',
      },
    ],
    headersDataTableShiftsDetailWorker: [
      {
        text: 'Nama Shift',
        align: 'center',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Tanggal Mulai',
        align: 'center',
        sortable: false,
        value: 'startDate',
      },
      {
        text: 'Tanggal Akhir',
        align: 'center',
        sortable: false,
        value: 'endDate',
      },
      {
        text: 'Nama Area',
        align: 'center',
        sortable: false,
        value: 'areaTitle',
      },
      {
        text: 'Lokasi',
        align: 'center',
        sortable: false,
        value: 'location',
      },
      {
        text: 'Tipe Pekerja',
        align: 'center',
        sortable: false,
        value: 'workerType',
      },
      {
        text: 'Uang Jasa',
        align: 'center',
        sortable: false,
        value: 'fee',
      },
      {
        text: 'Total Uang Jasa',
        align: 'center',
        sortable: false,
        value: 'totalFee',
      },
      {
        text: 'Harga Dasar',
        align: 'center',
        sortable: false,
        value: 'priceBase',
      },
      {
        text: 'Harga yang di Usulkan',
        align: 'center',
        sortable: false,
        value: 'priceProposed',
      },
      {
        text: 'Harga Murni yang di Usulkan',
        align: 'center',
        sortable: false,
        value: 'purePriceProposed',
      },
      {
        text: 'Total Dasar',
        align: 'center',
        sortable: false,
        value: 'totalBase',
      },
      {
        text: 'Total yang di Usulkan',
        align: 'center',
        sortable: false,
        value: 'totalProposed',
      },
      {
        text: 'Total Murni yang di Usulkan',
        align: 'center',
        sortable: false,
        value: 'pureTotalProposed',
      },
      {
        text: 'Quantity',
        align: 'center',
        sortable: false,
        value: 'quantity',
      },
    ],
    summary: null,
    searchWorker: null,
  }),

  computed: {
    getDataSalaryWeeks() {
      return this.$store.getters['salaryWeek/getDataSalaryWeek']
    },
  },

  watch: {
    searchClient() {
      this.getDataClient()
    },

    searchClientUpdate() {
      this.getDataClientUpdate()
    },
  },

  methods: {
    async getDataClient() {
      if (this.searchClient === null || this.searchClient === '') return false
      if (this.searchClient.length < 3) return false

      this.disabledSearchClient = true
      this.$store.commit('SET', { loading: true })
      await this.$store.dispatch('client/getAll', {
        query: {
          name: {
            $regex: this.searchClient,
            $options: 'i',
          },
          // active: false,
          // approved: true,
        },
      })
      this.dataClients = this.$store.state.client.data
      this.disabledSearchClient = false

      this.$nextTick(() => {
        this.$refs.searchClientField.focus()
      })

      this.$store.commit('SET', { loading: false })
    },

    async getDataClientUpdate() {
      if (this.searchClientUpdate === null || this.searchClientUpdate === '') return false
      if (this.searchClientUpdate.length < 3) return false

      this.disabledSearchClientUpdate = true
      this.$store.commit('SET', { loading: true })
      await this.$store.dispatch('client/getAll', {
        query: {
          name: {
            $regex: this.searchClientUpdate,
            $options: 'i',
          },
          active: true,
          approved: true,
        },
      })
      this.dataClients = this.$store.state.client.data
      this.disabledSearchClientUpdate = false

      // this.$nextTick(() => {
      //   this.$refs.searchClientFieldUpdate.focus()
      // })

      this.$store.commit('SET', { loading: false })
    },

    getEndDate(startDate) {
      const date = new Date(startDate)
      date.setDate(date.getDate() + 6)
      date.setHours(23, 59, 59) // Mengatur jam, menit, dan detik ke 23:59:59
      // return new Date(date.setDate(date.getDate() + 6))
      return date
    },

    async createSalaryWeek() {
      if (!this.title || this.title === '') return alert('Judul tidak boleh kosong')
      if (this.title && !this.title.trim()) return alert('Judul tidak boleh kosong')
      if (!this.clientId) return alert('Client harus dipilih')
      if (!this.startDate) return alert('Tanggal mulai harus diisi')
      if (this.labelRemarkSelected === 'ManualEntry') {
        if (!this.remark1 || this.remark1 === '') return alert('Remark 1 tidak boleh kosong')
        if (this.remark1 && !this.remark1.trim()) return alert('Remark 1 tidak boleh kosong')
      }

      const _startDate = new Date(new Date(this.startDate).setHours(0, 0, 0, 0))

      // console.log({
      //   start: _startDate,
      //   startDate: _startDate.toISOString(),
      //   endDate: this.getEndDate(_startDate),
      // })

      // return

      this.$store.commit('SET', { loading: true })
      const response = await this.$store.dispatch('salaryWeek/createSalaryWeek', {
        title: this.title,
        clientId: this.clientId,
        isCompleted: this.isCompleted,
        startDate: _startDate.toISOString(),
        endDate: this.getEndDate(_startDate).toISOString(),
        label_remark_1: this.labelRemarkSelected,
        remark_1: this.remark1,
        email: 'arsip.hayokerja@gmail.com',
      })

      if (response?.success) {
        await this.$store.dispatch('salaryWeek/getSalaryWeeks')
        this.dataSalaryWeeks = this.getDataSalaryWeeks
        console.log(this.dataSalaryWeeks)

        this.title = null
        this.searchClient = null
        this.dataClients = []
        this.clientId = null
        this.isCompleted = false
        this.startDate = null
        this.labelRemarkSelected = null
        this.remark1 = null
        this.showDialogCreateSalaryWeek = false
      }

      this.$store.commit('SET', { loading: false })
    },

    async showDetailSalaryWeek(salaryWeek) {
      const splitEndDate = salaryWeek.startDate.split('T')
      this.workersDetail = []
      this.attendancesDetail = []
      this.shiftsDetail = []
      this._idUpdate = salaryWeek._id
      this.detailSalaryWeek = true
      this.titleUpdate = salaryWeek.title
      this.clientNameUpdate = salaryWeek.clientName
      this.startDateUpdate = this.$moment(salaryWeek.startDate).format('YYYY-MM-DD')
      this.endDateUpdate = splitEndDate[0]
      this.labelRemarkSelectedUpdate = salaryWeek.label_remark_1
      this.remark1Update = salaryWeek.remark_1
      this.isCompletedUpdate = salaryWeek.isCompleted
      this.searchClientUpdate = salaryWeek.clientName
      this.clientIdUpdate = salaryWeek.clientId

      await this.getOneSalaryWeek()
    },

    async refreshSalaryWeek() {
      this.$store.commit('SET', { loading: true })
      const response = await this.$store.dispatch(`salaryWeek/refreshSalaryWeek`, {
        _id: this._idUpdate,
      })
      this.shiftsDetail = response.result.shifts
      this.attendancesDetail = response.result.attendances
      this.workersDetail = response.result.workers

      const responseSum = await this.$store.dispatch('salaryWeek/getSumSalaryWeek', {
        _id: this._idUpdate,
      })
      this.summary = responseSum.result
      this.$store.commit('SET', { loading: false })
    },

    async getOneSalaryWeek() {
      this.$store.commit('SET', { loading: true })
      const response = await this.$store.dispatch(`salaryWeek/getOneSalaryWeek`, {
        _id: this._idUpdate,
      })
      this.shiftsDetail = response.result.shifts
      this.attendancesDetail = response.result.attendances

      // this.workersDetail = response.result.workers , remove duplicate
      this.workersDetail = Object.values(
        response.result.workers?.reduce((acc, ar) => {
          if (!acc[ar.workerId]) acc[ar.workerId] = { ...ar }
          return acc
        }, {}),
      )
      // console.log(this.workersDetail)

      const responseSum = await this.$store.dispatch('salaryWeek/getSumSalaryWeek', {
        _id: this._idUpdate,
      })
      this.summary = responseSum.result
      this.summary.totalWorkers = this.workersDetail?.length || 0
      this.summary.totalSalary = this.workersDetail?.reduce((acc, curr) => acc + (curr?.totalSalary || 0), 0) || 0
      // console.log(response, 'Get One Salary Week')
      // console.log(responseSum, 'Get Sum Salary Week')
      // // attendancesDetail.filter((attendance) => attendance.workerId === item.workerId)
      // console.log(
      //   this.attendancesDetail.filter((attendance) => {
      //     return attendance.workerId !== undefined && attendance.workerId === '3a80d2ee-cdbb-4c52-b4a7-df30d184a163'
      //   }),
      //   'Workers Detail',
      // )
      this.$store.commit('SET', { loading: false })
    },

    async updateSalaryWeek() {
      this.$store.commit('SET', { loading: true })

      const _startDate = new Date(new Date(this.startDateUpdate).setHours(0, 0, 0, 0))

      const response = await this.$store.dispatch('salaryWeek/updateSalaryWeek', {
        _id: this._idUpdate,
        title: this.titleUpdate,
        clientId: this.clientIdUpdate,
        isCompleted: this.isCompletedUpdate,
        startDate: _startDate.toISOString(),
        endDate: this.getEndDate(_startDate).toISOString(),
        label_remark_1: this.labelRemarkSelectedUpdate,
        remark_1: this.remark1Update,
        email: 'arsip.hayokerja@gmail.com',
      })

      await this.$store.dispatch('salaryWeek/getSalaryWeeks')
      this.$store.commit('SET', { loading: false })
      this.dataSalaryWeeks = this.getDataSalaryWeeks
      this.detailSalaryWeek = false
    },

    async deleteSalaryWeek(item) {
      const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
      if (!confirm) return false
      this.$store.commit('SET', { loading: true })
      const response = await this.$store.dispatch('salaryWeek/deleteSalaryWeek', {
        _id: item._id,
      })
      await this.$store.dispatch('salaryWeek/getSalaryWeeks')
      this.dataSalaryWeeks = this.getDataSalaryWeeks
      this.$store.commit('SET', { loading: false })
    },

    async generateXlsx(item) {
      const isConfirmed = confirm('Yakin ekspor dengan filter saat ini?')
      if (!isConfirmed) return false
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        // const realm = await this.$realm()
        // const _sw = await realm.functions.getSalaryWeeks(JSON.stringify({ _id: this._idUpdate }))
        // console.log(_sw)

        // todo:  from backend
        await this.$api
          .post(
            `SalaryWeeks/generateXlsx/`,
            {
              _id: this._idUpdate,
            },
            {
              responseType: 'blob',
              onDownloadProgress: (progressEvent) => {
                const total = parseFloat(
                  progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
                )
                const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
                const percentCompleted = Math.floor((current / total) * 100)
              },
            },
          )
          .then((data) => {
            // console.log(data)
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `Salary week client ${this.clientNameUpdate}- tanggal mulai ${this.startDateUpdate}.xlsx`,
            )
            document.body.appendChild(link)
            link.click()
          })
      } catch (err) {
        console.log(err)
        alert('Gagal export data')
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },

    convertToRupiah(value) {
      const formattedNumber = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      }).format(value)

      return formattedNumber
    },
  },

  async mounted() {
    this.mainLoading = true
    this.$store.commit('SET', { loading: true })
    await this.$store.dispatch('salaryWeek/getSalaryWeeks')
    this.dataSalaryWeeks = this.getDataSalaryWeeks
    console.log(this.dataSalaryWeeks)
    this.$store.commit('SET', { loading: false })
    this.mainLoading = false
  },
}
</script>

<style scoped></style>
